import * as actionTypes from "../actionTypes";
import {
  CreateSurveyLibraries, GetSurveyLibraries, GetSurveyDetails, UpdateSurveyLibrary, LaunchSurvey, TakeSurvey, getLaunchSurveyDetailsById,
  GetSurveyReports, GetSurveyOverviewDetails, GetTopSurveyLocations, GetTopSurveyDepartments, GetSurveyReportData 
} from "../../constants/apiConstants";
import {http} from "../../http";
import {toast} from "react-toastify";
import {getListOfThingsToCompleteAPI} from '../index';
const getSurveyLibraries = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(GetSurveyLibraries, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
      } else {

        dispatch({ type: actionTypes.GET_SURVEY_LIBRARIES, surveyLibraries: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const addSurvey = (data, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.post(CreateSurveyLibraries, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success(res[0]);
          dispatch({type: actionTypes.SURVEY_LOADING_FALSE});
          history.push('/company/survey-library');
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
}

const getSurveyDetails = (id, history, route, pushStatus = false) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${GetSurveyDetails}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        dispatch(errorOccurred(res.message));
        history.push('/company/survey-library');
      } else {
        dispatch({ type: actionTypes.GET_SURVEY_DETAILS, surveyDetails: res[0]});
        pushStatus ? null : history.push(route);
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const editSurvey = (data, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.put(UpdateSurveyLibrary, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success(res[0]);
          dispatch({type: actionTypes.SURVEY_LOADING_FALSE});
          history.push('/company/survey-library');
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      history.push('/company/survey-library');
    }
  }
}

const launchSurvey = (data, history, pushStatus = false) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.post(LaunchSurvey, data, {headers: { AUTHTOKEN: AuthToken }});
      if(res) {
        if (res.message) {
          toast.error(res.message);
        } else {
          toast.success(res[0]);
          dispatch({type: actionTypes.SURVEY_LOADING_FALSE});
          pushStatus ? null : history.push('/company/survey-library');
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
      history.push('/company/survey-library');
    }
  }
}

const errorOccurred = (error) => (dispatch) => dispatch({ type: actionTypes.FAILED_GET_SURVEY_LIBRARIES, error });

const networkError = (error) => (dispatch) => dispatch({type: actionTypes.NETWORK_ERROR, networkError: error});

const FetchSurveyQuestions = (id, history,successFunc) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${getLaunchSurveyDetailsById}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        if(res) {
          dispatch({type: actionTypes.FETCH_SURVEY_QUESTIONS, surveyQuestions: res[0]['data'], hasSections: res[0]['has_sections'], LaunchSurveyDetails: res[0]});
          dispatch({type: actionTypes.SURVEY_LOADING_FALSE});
          if(successFunc){
            successFunc()
          }else{history.push('/survey')}
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const submitSurvey = (surveydata, history) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.post(`${TakeSurvey}`, surveydata, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        if(res) {
          toast.success(res[0]);
          dispatch(getListOfThingsToCompleteAPI());
          history.push('/portal')
        }
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const emptySurveyQuestion = () => (dispatch) => dispatch({type: actionTypes.EMPTY_SURVEY_QUESTIONS});
const setSurveyQuestion = (res, history, staticSurveyStatus) => (dispatch) => {
  dispatch({type: actionTypes.FETCH_SURVEY_QUESTIONS, surveyQuestions: res[0]['data'], hasSections: res[0]['has_sections'], LaunchSurveyDetails: res[0], staticSurveyStatus: staticSurveyStatus});
  history.push('/survey');
}
const getSurveyReports = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${GetSurveyReports}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_SURVEY_REPORT_DETAILS,  surveyReportDetails: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getSurveyOverviewDetails = (companyId, surveyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${GetSurveyOverviewDetails}/${companyId}/${surveyId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_SURVEY_OVERVIEW_DETAILS, surveyOverviewDetails: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getTopSurveyLocations = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${GetTopSurveyLocations}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_SURVEY_TOP_LOCATIONS,  surveyTopLocations: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};
const getTopSurveyDepartments = (id) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${GetTopSurveyDepartments}/${id}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_SURVEY_TOP_DEPARTMENTS,  surveyTopDepartments: res[0]});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

const getSurveyReportData = (surveyId) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    dispatch({type: actionTypes.SURVEY_LOADING});
    try {
      const res = await http.get(`${GetSurveyReportData}/${surveyId}`, {headers: { AUTHTOKEN: AuthToken }});
      if(res.message) {
        toast.error(res.message);
      } else {
        dispatch({ type: actionTypes.GET_SURVEY_REPORTS_DATA,  surveyReportsData: res});
      }
    } catch(error) {
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
  }
};

export { getSurveyLibraries, addSurvey, editSurvey, getSurveyDetails, launchSurvey, FetchSurveyQuestions, submitSurvey, emptySurveyQuestion, setSurveyQuestion,
  getSurveyReports, getSurveyOverviewDetails, getTopSurveyLocations, getTopSurveyDepartments, getSurveyReportData};